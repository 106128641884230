import React, { useEffect, useRef, useContext } from "react";
import {
  StyledHiveSlider,
  StyledHiveSwiper,
  StyledHiveSwiperSlider,
  StyledHiveSwiperControls,
  StyledHiveSwiperControlsPrev,
  StyledHiveSwiperControlsNext,
  StyledHiveSwiperSlide,
  StyledHiveSwiperSlideTitle,
  StyledHiveSliderText,
  StyledHiveSwiperSlideProsCons,
  StyledHiveSwiperSlidePros,
  StyledHiveSwiperSlideCons,
  StyledHiveSwiperSlideProsConsBig,
  StyledHiveSwiperSlideProsConsSmall,
} from "./style.jsx";
import Swiper, { Navigation, Controller } from "swiper";
import { StyledGridRow } from "../../commons/Grid";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { DataContext } from "../../contexts/DataContext.jsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

Swiper.use([Navigation, Controller]);

export default ({ items }) => {
  const sliderProConsEl = useRef(null);
  const sliderEl = useRef(null);
  const prevArrowEl = useRef(null);
  const nextArrowEl = useRef(null);
  const { pageImages } = useContext(DataContext);

  useEffect(() => {
    if (sliderEl && sliderProConsEl) {
      const slider = new Swiper(sliderEl.current, {
        allowTouchMove: "ontouchstart" in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        virtualTranslate: true,
        navigation: {
          prevEl: prevArrowEl.current,
          nextEl: nextArrowEl.current,
        },
      });
      const sliderProCons = new Swiper(sliderProConsEl.current, {
        allowTouchMove: "ontouchstart" in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        virtualTranslate: true,
        controller: {
          control: slider,
        },
      });

      slider.controller.control = sliderProCons;
    }
  }, [sliderEl, sliderProConsEl]);

  return (
    <StyledHiveSlider>
        <StyledHiveSwiper ref={sliderEl} className="swiper-container">
          <StyledHiveSwiperSlider className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledHiveSwiperSlide className="swiper-slide" key={index}>
                <GatsbyImage
                  loading="lazy"
                  style={{ maxWidth: "500px"}} 
                  imgStyle={{ borderRadius: "10px" }}
                  image={getImage(pageImages.find((e) => e.path === item.image))}
                  alt={pageImages.find((e) => e.path === item.image)?.alt}
                />
                <StyledHiveSwiperSlideTitle>
                  {item.title}
                </StyledHiveSwiperSlideTitle>
              </StyledHiveSwiperSlide>
            ))}
            <StyledHiveSwiperControls>
              <StyledHiveSwiperControlsPrev ref={prevArrowEl}>
                <Icon icon={icons.triangleLeft} color={Colors.yellow} />
              </StyledHiveSwiperControlsPrev>
              <StyledHiveSwiperControlsNext ref={nextArrowEl}>
                <Icon icon={icons.triangleRight} color={Colors.yellow} />
              </StyledHiveSwiperControlsNext>
            </StyledHiveSwiperControls>
          </StyledHiveSwiperSlider>
        </StyledHiveSwiper>
        <StyledHiveSwiper ref={sliderProConsEl} className="swiper-container">
          <StyledHiveSwiperSlider className="swiper-wrapper">
            {items.map((item, index) => (
              <StyledHiveSwiperSlideProsCons
                className="swiper-slide"
                key={index}
              >
                <StyledHiveSwiperSlidePros>
                  <StyledHiveSwiperSlideProsConsBig dangerouslySetInnerHTML={{ __html: item.pros.big }} />
                  <StyledHiveSwiperSlideProsConsSmall dangerouslySetInnerHTML={{ __html: item.pros.small }} />
                </StyledHiveSwiperSlidePros>
                <StyledHiveSwiperSlideCons>
                  <StyledHiveSwiperSlideProsConsBig dangerouslySetInnerHTML={{ __html: item.cons.big }} />
                  <StyledHiveSwiperSlideProsConsSmall dangerouslySetInnerHTML={{ __html: item.cons.small }} />
                </StyledHiveSwiperSlideCons>
                <StyledHiveSliderText>{item.text}</StyledHiveSliderText>
              </StyledHiveSwiperSlideProsCons>
            ))}
          </StyledHiveSwiperSlider>
        </StyledHiveSwiper>
    </StyledHiveSlider>
  );
};
