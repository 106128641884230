import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledHiveSlider = styled.div`
  position: relative;
  margin: 0 auto 0;
  padding: 0 ${Rem(20)} 0 ${Rem(25)};
`;

export const StyledHiveSwiper = styled.div`
  &:first-child {
    margin-bottom: ${Rem(0)};

    @media (${BP.ipad}) {
      padding-bottom: ${Rem(0)};
    }
  }

  &:last-child {
    @media (${BP.ipad}) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
    }

    ${() => css`
      ${StyledHiveSwiperSlider},
      ${StyledHiveSwiperSlide} {
        @media (${BP.ipad}) {
          height: 100% !important;
          pointer-events: none !important;
        }
      }
    `}
  }
`;

export const StyledHiveSwiperSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHiveSwiperControls = styled.div`
  position: absolute;
  bottom: ${Rem(-2)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  pointer-events: none;
  width: 100%;
  max-width: ${Rem(450)};
  display: flex;
  justify-content: space-between;
  padding: 0 ${Rem(20)};
`;

export const StyledHiveSwiperControlsPrev = styled.div`
  cursor: pointer;
  pointer-events: all;

  &.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const StyledHiveSwiperControlsNext = styled(
  StyledHiveSwiperControlsPrev,
)``;

export const StyledHiveSwiperSlide = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-out 0s;
  text-align: center;

  &.swiper-slide-active {
    opacity: 1;
    pointer-events: all;
  }
`;

export const StyledHiveSwiperSlideImage = styled.img`
  width: 100%;
  max-width: ${Rem(500)};
  margin-bottom: ${Rem(32)};
`;

export const StyledHiveSwiperSlideTitle = styled.div`
  font-size: ${Rem(26)};
  margin-top: ${Rem(20)};
  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
  }
`;

export const StyledHiveSliderText = styled.p`
  font-size: ${Rem(15)};
  min-width: 100%;
  font-size: ${Rem(15)};
  line-height: ${Rem(19)};
  padding-top: ${Rem(32)};

  @media (${BP.ipad}) {
    position: absolute;
    bottom: ${Rem(6)};
    font-size: ${Rem(25)};
  }
`;

export const StyledHiveSwiperSlideProsCons = styled(StyledHiveSwiperSlide)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  max-width: ${Rem(960)};

  &.swiper-slide-active {
    opacity: 1;
    pointer-events: all;
  }
`;

export const StyledHiveSwiperSlidePros = styled.div`
  padding-top: ${Rem(12)};
  color: ${Colors.yellow};
  border-top: 1px solid ${Colors.yellow};
  text-align: left;
  max-width: calc(50% - ${Rem(12)});

  @media (${BP.ipad}) {
    padding-right: ${Rem(24)};
  }
`;

export const StyledHiveSwiperSlideCons = styled(StyledHiveSwiperSlidePros)`
  color: ${Colors.gray};
  border-top-color: ${Colors.gray};
  text-align: right;

  @media (${BP.ipad}) {
    padding-right: 0;
    padding-left: ${Rem(24)};
  }
`;

export const StyledHiveSwiperSlideProsConsBig = styled.div`
  font-size: ${Rem(26)};
  padding-bottom: ${Rem(12)};
  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
  }
`;

export const StyledHiveSwiperSlideProsConsSmall = styled.div`
  font-size: ${Rem(20)};
  max-width: 300px;
`;
